// import React from 'react';
// import CreatorWerse from "../assets/CreatorWerse.png";

// const Navbar = () => {
//     return (
//         <nav className="navbar bg-dark text-white p-3">
//             <div className="container-fluid">
//                 <img src={CreatorWerse} alt="Logo" width="80" height="80" />
//                 <h3>Admin Panel</h3>
//             </div>
//         </nav>
//     );
// };

// export default Navbar;


import React from 'react';
 import CreatorWerse from "../assets/CreatorWerse.png";

const Navbar = () => {
  return (
    <nav className="navbar navbar-dark bg-dark">
      <div className="container-fluid justify-content-center">
        <div className="d-flex align-items-center">
          <img
            src={CreatorWerse} 
            alt="CreatorWerse Logo"
            className="me-3"
            width="80"
            height="80"
          />
          {/* <h3 className="text-white mb-0">Admin Panel</h3> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

