// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import Table from './Table';

// const UserList = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       const formData = new FormData();
//       formData.append('action', 'list');

//       const config = {
//         method: 'post',
//         maxBodyLength: Infinity,
//         url: 'http://creatorwerse.pmpframe.com/api/login.php',
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         },
//         data: formData
//       };

//       try {
//         const response = await axios.request(config);
//         if (response.data.success) {
//           setData(response.data.data);
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (loading) return <p>Loading...</p>;

//   return <Table data={data} />;
// };

// export default UserList;





// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import Table from './Table';

// const UserList = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       const formData = new FormData();
//       formData.append('action', 'list');

//       const config = {
//         method: 'post',
//         maxBodyLength: Infinity,
//         url: 'http://creatorwerse.pmpframe.com/api/login.php',
//         headers: {
//                  'Content-Type': 'multipart/form-data'
//                     },
//         data: formData
//       };

//       try {
//         const response = await axios(config); // Using axios directly instead of axios.request
        
//         console.log('API Response:', response); // Debug log
        
//         if (response.data && response.data.success) {
//           setData(response.data.data || []);
//           setError(null);
//         } else {
//           throw new Error(response.data?.message || 'Failed to fetch user list');
//         }
//       } catch (error) {
//         console.error('Error details:', error);
//         setError(error.message || 'Failed to fetch user list');
//         setData([]);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (loading) {
//     return (
//       <div className="p-4">
//         <p>Loading user list...</p>
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div className="p-4 text-red-600">
//         <p>Error: {error}</p>
//         <p>Please try again later or contact support if the issue persists.</p>
//       </div>
//     );
//   }

//   return <Table data={data} />;
// };

// export default UserList;




// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import Table from './Table';

// const UserList = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       const formData = new FormData();
//       formData.append('action', 'list');

//       const config = {
//         method: 'post',
//         url: 'http://creatorwerse.pmpframe.com/api/login.php',
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         },
//         data: formData
//       };

//       try {
//         const response = await axios(config);
//         console.log('API Response:', response);

//         if (response.data && Array.isArray(response.data)) {
//           // Handle direct array response
//           setData(response.data);
//           setError(null);
//         } else if (response.data && response.data.success && Array.isArray(response.data.data)) {
//           // Handle wrapped response with success flag
//           setData(response.data.data);
//           setError(null);
//         } else {
//           throw new Error('Invalid data format received');
//         }
//       } catch (error) {
//         console.error('Error details:', error);
//         setError(error.message || 'Failed to fetch user list');
//         setData([]);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (loading) return <div className="p-4">Loading user list...</div>;
//   if (error) {
//     return (
//       <div className="p-4 text-red-600">
//         <p>Error: {error}</p>
//         <p>Please try again later or contact support if the issue persists.</p>
//       </div>
//     );
//   }

//   return <Table data={data} />;
// };

// export default UserList;



// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import Table from './Table';

// const UserList = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       const formData = new FormData();
//       formData.append('action', 'list');

//       const config = {
//         method: 'post',
//         url: 'https://creatorwerse.pmpframe.com/api/login.php',
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         },
//         data: formData
//       };

//       try {
//         const response = await axios(config);
//         console.log('API Response:', response);

//         if (response.data && Array.isArray(response.data)) {
//           setData(response.data);
//           setError(null);
//         } else if (response.data && response.data.success && Array.isArray(response.data.data)) {
//           setData(response.data.data);
//           setError(null);
//         } else {
//           throw new Error('Invalid data format received');
//         }
//       } catch (error) {
//         console.error('Error details:', error);
//         setError(error.message || 'Failed to fetch user list');
//         setData([]);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (loading) {
//     return (
//       <div className="container mt-4">
//         <div className="spinner-border text-primary" role="status">
//           <span className="visually-hidden">Loading...</span>
//         </div>
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div className="container mt-4">
//         <div className="alert alert-danger" role="alert">
//           <h4 className="alert-heading">Error!</h4>
//           <p>{error}</p>
//           <hr />
//           <p className="mb-0">Please try again later or contact support if the issue persists.</p>
//         </div>
//       </div>
//     );
//   }

//   return <Table data={data} />;
// };

// export default UserList;



import React, { useEffect, useState } from 'react';
import axios from 'axios';

const UserManagement = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setStatuses(data.map(user => user.STATUS === 1));
    }
  }, [data]);

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('action', 'list');

    try {
      const response = await axios({
        method: 'post',
        url: 'https://creatorwerse.pmpframe.com/api/login.php',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      });

      if (response.data && Array.isArray(response.data)) {
        setData(response.data);
        setError(null);
      } else if (response.data && response.data.success && Array.isArray(response.data.data)) {
        setData(response.data.data);
        setError(null);
      } else {
        throw new Error('Invalid data format received');
      }
    } catch (error) {
      console.error('Error details:', error);
      setError(error.message || 'Failed to fetch user list');
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = async (index, email) => {
    const updatedStatuses = [...statuses];
    const newStatus = updatedStatuses[index] ? 2 : 1;

    // Optimistic update
    updatedStatuses[index] = !updatedStatuses[index];
    setStatuses(updatedStatuses);

    const formData = new FormData();
    formData.append('action', 'updatestatus');
    formData.append('email', email);
    formData.append('status', newStatus.toString());

    try {
      const response = await axios({
        method: 'post',
        url: 'https://creatorwerse.pmpframe.com/api/login.php',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to update status');
      }

      console.log('Status update successful:', response.data);
    } catch (error) {
      console.error('Error updating status:', error);
      
      // Revert the optimistic update
      updatedStatuses[index] = !updatedStatuses[index];
      setStatuses(updatedStatuses);
      
      alert(`Failed to update status: ${error.message}`);
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container-fluid vh-100 d-flex align-items-center justify-content-center">
        <div className="alert alert-danger w-75" role="alert">
          <h4 className="alert-heading">Error!</h4>
          <p>{error}</p>
          <hr />
          <p className="mb-0">Please try again later or contact support if the issue persists.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid vh-100 p-4">
      <div className="row h-100">
        <div className="col-12">
          <div className="card h-100">
            <div className="card-header bg-dark text-white">
              <h3 className="mb-0">User Management</h3>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-bordered table-hover table-striped">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col" className="align-middle">ID</th>
                      <th scope="col" className="align-middle">First Name</th>
                      <th scope="col" className="align-middle">Last Name</th>
                      <th scope="col" className="align-middle">Mobile</th>
                      <th scope="col" className="align-middle">Email</th>
                      <th scope="col" className="align-middle">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((user, index) => (
                      <tr key={user.ID}>
                        <td className="align-middle">{user.ID}</td>
                        <td className="align-middle">{user.FIRST_NAME}</td>
                        <td className="align-middle">{user.LAST_NAME}</td>
                        <td className="align-middle">{user.MOBILE}</td>
                        <td className="align-middle">{user.EMAIL}</td>
                        <td className="align-middle">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id={`statusSwitch${user.ID}`}
                              checked={statuses[index]}
                              onChange={() => handleToggle(index, user.EMAIL)}
                            />
                            <label
                              className={`form-check-label ${statuses[index] ? 'text-success' : 'text-danger'}`}
                              htmlFor={`statusSwitch${user.ID}`}
                            >
                              {statuses[index] ? 'Active' : 'Inactive'}
                            </label>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;

