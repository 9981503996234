// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Header from './components/Header';
// // import Home from './pages/Home';
// // import About from './pages/About';
// function App() {
//   return (
//     <Router>
//     <Header />
//     <Routes>
//       {/* <Route path="/" element={<Home />} />
//         <Route path="/about" element={<About />} /> */}
//     </Routes>
//   </Router>
//   );
// }

// export default App;




// import React, { useState } from 'react';
// import Navbar from './components/Navbar';
// import Sidebar from './components/Sidebar';
// import UserList from './components/UserList';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './App.css';

// const App = () => {
//     const [activeComponent, setActiveComponent] = useState(null);

//     return (
//         <div className="app-container d-flex">
          
//             <Sidebar onSelect={setActiveComponent} />
//             <div className="content w-100">
//                 <Navbar />
//                 <div className="p-4">
//                     {activeComponent === 'userlist' && <UserList />}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default App;


import React from 'react';
import Navbar from './components/Navbar';
import UserList from './components/UserList';
 import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <div className="d-flex flex-column vh-100">
      <Navbar />
      <UserList />
    </div>
  );
};

export default App;